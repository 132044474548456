import { Component, Inject, EventEmitter, Input, Output } from '@angular/core';
import { PopupService } from '../../shared/services/popup.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-custom-popup',
  standalone: true,
  imports: [],
  templateUrl: './custom-popup.component.html',
  styleUrl: './custom-popup.component.scss'
})
export class CustomPopupComponent {

  constructor(
    private dialogRef: MatDialogRef<CustomPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private popupService: PopupService
  ) {
      console.log(data);
    }

  closePopup() {
    this.popupService.closePopup();
  }

}
