@if (data.type == 1) {
    <div class="popup-backdrop">
        <div class="popup-container">
        <div class="popup-header">
            <button class="close-btn" (click)="closePopup()">X</button>
        </div>
        <div class="popup-content">
            <p class="message-content">{{ data.message }}</p>
        </div>
        </div>
    </div>
}
