import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomPopupComponent } from '../../login/custom-popup/custom-popup.component';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

    constructor(private dialog: MatDialog) {}

    openPopup(data?: any) {
        this.dialog.open(CustomPopupComponent, {
            data: data
        });
    }

    closePopup() {
      this.dialog.closeAll();
    }
}