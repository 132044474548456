import { Injectable } from "@angular/core";
import Swal from "sweetalert2";

@Injectable({
    providedIn: 'root'
})
export class SwalToastService {
    toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
        color: 'var(--color-black)',
        background: 'var(--color-white)',
        showClass: {
            popup: 'animate__animated animate__slideInRight animate__fast'
        },
        hideClass: {
            popup: 'animate__animated animate__fadeOut animate__fast'
        }
    });

    show(text: string, title?: string) {
        var margin = 7;
        if (!title) {
            title = '';
            margin = 0;
        }
        this.toast.fire({
            icon: null,
            html: '<div style="min-height: 40px; display: flex; flex-direction: column; justify-content: center">' +
                    '<div style="font-size: var(--font-size-l); font-weight: 600; margin: ' + margin + 'px 0">' + title + '</div>' +
                    '<div style="font-size: var(--font-size-m)">' + text + '</div></div>'
        });
    }

    success(text?: string, title?: string) {
        var margin = 7;
        if (!text) {
            text = '';
        }
        if (!title) {
            title = '';
            margin = 0;
        }
        this.toast.fire({
            icon: 'success',
            html: '<div style="min-height: 40px; display: flex; flex-direction: column; justify-content: center">' +
                    '<div style="font-size: var(--font-size-l); font-weight: 600; margin: ' + margin + 'px 0">' + title + '</div>' +
                    '<div style="font-size: var(--font-size-m)">' + text + '</div></div>'
        });
    }

    error(text?: string, title?: string) {
        var margin = 7;
        if (!text) {
            text = '';
        }
        if (!title) {
            title = '';
            margin = 0;
        }
        this.toast.fire({
            icon: 'error',
            html: '<div style="min-height: 40px; display: flex; flex-direction: column; justify-content: center">' +
                    '<div style="font-size: var(--font-size-l); font-weight: 600; margin: ' + margin + 'px 0">' + title + '</div>' +
                    '<div style="font-size: var(--font-size-m)">' + text + '</div></div>'
        });
    }

    info(text?: string, title?: string) {
        var margin = 7;
        if (!text) {
            text = '';
        }
        if (!title) {
            title = '';
            margin = 0;
        }
        this.toast.fire({
            icon: 'info',
            iconColor: 'var(--dark-grey)',
            html: '<div style="min-height: 40px; display: flex; flex-direction: column; justify-content: center">' +
                    '<div style="font-size: var(--font-size-l); font-weight: 600; margin: ' + margin + 'px 0">' + title + '</div>' +
                    '<div style="font-size: var(--font-size-m)">' + text + '</div></div>'
        });
    }

    warning(text?: string, title?: string) {
        var margin = 7;
        if (!text) {
            text = '';
        }
        if (!title) {
            title = '';
            margin = 0;
        }
        this.toast.fire({
            icon: 'warning',
            html: '<div style="min-height: 40px; display: flex; flex-direction: column; justify-content: center">' +
                    '<div style="font-size: var(--font-size-l); font-weight: 600; margin: ' + margin + 'px 0">' + title + '</div>' +
                    '<div style="font-size: var(--font-size-m)">' + text + '</div></div>'
        });
    }
}