// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    staging: true,
    firebaseConfig: {
      apiKey: "AIzaSyAeg7cQsdn0DN68NZq0_478fq7OQVouGKU",
      authDomain: "v2c-app.firebaseapp.com",
      databaseURL: "https://v2c-app.firebaseio.com",
      projectId: "v2c-app",
      storageBucket: "v2c-app.appspot.com",
      messagingSenderId: "600121721573",
      appId: "1:600121721573:web:d27af2eeebccbec4354a0c"
    },
    v2cServiceConfig: {
      basicOAuth: "Y2xvdWRAdjJjLmNvbToxMjM0",
      basePath: "https://staging.v2c.cloud/v2cservice"
    },
    v2cPaymentsServiceConfig: {
      basicOAuth: "Y2xvdWRAdjJjLmNvbToxMjM0",
      basePath: "https://staging.v2c.cloud/paymentservice"
    },
    v2cInstallationServiceConfig:{
      basicOAuth: "Y2xvdWRAdjJjLmNvbToxMjM0",
      basePath: "https://staging.v2c.cloud/v2cinstallationservice"
    }
  };